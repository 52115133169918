/* -----------NOVO LAYOUT------------------ */

@tailwind base;
@tailwind components;
@tailwind utilities;


.amber500 {
    color: #f59e0b;
    
}
.LBamber500{
  border-left: 4px solid #f59e0b;
}
  
.red700 {
    color: #b91c1c;
}

.LBred700{
   border-left: 4px solid #b91c1c;
 }

.emerald600 {
    color: #059669;
}

.LBemerald600{
  border-left: 4px solid #059669;
}

.orange600 {
    color: #ea580c;
}

.LBorange600{
  border-left: 4px solid #ea580c;
}

.fuchsia800 {
    color: #86198f;
}

.BLfuchsia800{
  border-left: 4px solid #86198f;
}

.pink600 {
    color: #db2777;
}


.LBpink600{
  border-left: 4px solid #db2777;
}



/* REACTOR SGEM */

.semi_arc {
  width: 100px;
  height: 100px;
  border: 6px solid rgba(12, 171, 245);
  background: transparent;
  border-radius: 50%;
  transform: rotateZ(0deg);
  transition: box-shadow 3s ease;
  text-align: center;
  line-height: 100px;
}

.semi_arc:hover {
  box-shadow: 0px 0px 30px rgba(12, 171, 245, 0.8);
  transition: 0.3s;
}

.semi_arc_3 {
  content: "";
  position: absolute;
  width: 94%;
  height: 94%;
  left: 3%;
  top: 3%;
  border: 5px solid rgba(12, 171, 245);
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotate 4s linear infinite;
  text-align: center;
  line-height: 129px;
}

.e5 {
  width: 200px;
  height: 200px;
}

.e5_1 {
  color: rgba(12, 171, 245, 0.15);
  border: 2px solid;
  border-left: 2px solid transparent;
  animation: rotate 5s linear infinite;
}

.e5_2 {
  color: rgba(12, 171, 245, 0.7);
  border: 4px solid;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  animation: rotate_anti 4s linear infinite;
}

.e5_3 {
  color: rgba(12, 171, 245, 0.5);
  border: 2px solid;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  animation: rotate 3s linear infinite;
}

.e5_4 {
  color: rgba(12, 171, 245, 0.15);
  border: 4px solid;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  animation: rotate_anti 2s linear infinite;
}

/* option {
    color: var(--cor-font-body);
    background-color: var(--cor-primaria) !important;
  }
   */
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes rotate_anti {
  0% {
    transform: rotateZ(360deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}

@keyframes colour_ease {
  0% {
    border-color: #0CABF5;
  }

  50% {
    border-color: rgba(12, 171, 245, 0.5);
  }

  100% {
    border-color: #0CABF5;
  }
}

@keyframes flicker {
  0% {
    box-shadow: 0px 0px 16px 8px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }

  40% {
    box-shadow: 0px 0px 16px 8px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }

  50% {
    box-shadow: 0px 0px 16px 6px rgba(150, 255, 255, 0.5), inset 0px 1px 100px 2px rgba(21, 211, 233, 0.3);
  }

  60% {
    box-shadow: 0px 0px 16px 8px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }

  100% {
    box-shadow: 0px 0px 16px 8px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
}

@keyframes flicker2 {
  0% {
    box-shadow: 0px 0px 60px 25px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }

  40% {
    box-shadow: 0px 0px 60px 25px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }

  50% {
    box-shadow: 0px 0px 50px 17px rgba(150, 255, 255, 0.5), inset 0px 1px 100px 2px rgba(21, 211, 233, 0.3);
  }

  60% {
    box-shadow: 0px 0px 60px 25px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }

  100% {
    box-shadow: 0px 0px 60px 25px rgba(150, 255, 255, 0.5), inset 0px 1px 4px 2px rgba(21, 211, 233, 0.3);
  }
}

@keyframes big_flicker {
  0% {
    box-shadow: 0px 0px 40px 20px rgba(150, 255, 255, 0.5), inset 0px 1px 30px 15px rgba(21, 211, 233, 0.3);
  }

  40% {
    box-shadow: 0px 0px 40px 20px rgba(150, 255, 255, 0.5), inset 0px 1px 30px 15px rgba(21, 211, 233, 0.3);
  }

  50% {
    box-shadow: 0px 0px 35px 17px rgba(150, 255, 255, 0.5), inset 0px 1px 50px 40px rgba(21, 211, 233, 0.3);
  }

  60% {
    box-shadow: 0px 0px 40px 20px rgba(150, 255, 255, 0.5), inset 0px 1px 30px 15px rgba(21, 211, 233, 0.3);
  }

  100% {
    box-shadow: 0px 0px 40px 20px rgba(150, 255, 255, 0.5), inset 0px 1px 30px 15px rgba(21, 211, 233, 0.3);
  }
}

.toast-container {
    @apply border border-gray-700 !bg-gray-900 !shadow-lg;
  }
  

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]:focus {
  outline: 0;
}

option {
  background-color: #0F162A;
}